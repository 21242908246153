import React, { useContext, useEffect, useState, useRef } from "react";
import { ReactComponent as LeftArrow } from "../../constants/images/left-arrow.svg";
import { ReactComponent as RightArrow } from "../../constants/images/card-right-arrow.svg";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { images } from "constants";
import ProvisionsCard from "components/cards/p_cards";
import { Button } from "components/buttons";
import { ModalContext } from "routes/layout";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Image from "components/lazyImage";

const AboutUs = () => {
  const [travellers, setTravellers] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);
  const { showModal, setshowModal, closeModal, toggleModal } =
    useContext(ModalContext);
  // const next = () => {
  //   currentSlide < 3 ? setCurrentSlide(currentSlide + 1) : setCurrentSlide(0);
  // };
  // const prev = () => {
  //   currentSlide === 0 ? setCurrentSlide(3) : setCurrentSlide(currentSlide - 1);
  // };

  useEffect(() => {
    const interval = setInterval(() => {
      setTravellers((prevMyState) => (prevMyState + 1) % 3);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  const sliderRef = useRef(null);

  const next = () => {
    sliderRef.current.slickNext();
  };

  const previous = () => {
    sliderRef.current.slickPrev();
  };

  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 7000,
  };

  const CarouselData = [
    {
      image: images.product,
      title: "Full stack tools to help you plan your dream African experience.",
      content:
        "We have carefully crafted the right tools to help you tailor your experience in Africa based on your interests and personal preferences.",
    },
    {
      image: images.community,
      title: "Great connection with the local community",
      content:
        "Intentional and important partnerships have been made to help you reconnect with your roots . It’s culture, history, culinary experiences, music, wildlife safaris and lots more.",
    },
    {
      image: images.guide,
      title: "Knowledgeable travel guides",
      content:
        "We provide access to knowledgeable and experienced local guides to give you context and insights of where you’d be would be visiting.",
    },
    {
      image: images.explore,
      title: "Safe and Comfortable  travel experiences.",
      content:
        "Your safety and comfortability throughout your trip cannot be overstated. We provide transportation, accommodation, meals that meets your needs and preferences. We also make sure that our tours adhere to strict safety and security standards.",
    },
  ];

  return (
    <div className="bg-[#F5F7F9] lg:px-[100px] lg:py-[40px] sm:px-[50px] sm:py-[25px] py-[24px] px-[16px] flex flex-col sm:gap-[36px] gap-[24px]">
      <div className=" bg-white flex flex-col gap-y-[44px] rounded-[16px] sm:rounded-[24px] pb-5">
        <div className="flex flex-col w-[85%] mx-auto py-5">
          <div className="font-outfit text-[#0E4F6D] font-bold text-[24px] sm:text-[48px] flex flex-col justify-center items-center">
            <p className="sm:text-center text-left w-full">We are helping </p>
            <p className="sm:text-center text-left sm:w-fit w-full ">
              {" "}
              {travellers === 0 && (
                <p className="bg-[#E9F6FC] px-3 py-2 rounded-[8px] w-fit">
                  You!
                </p>
              )}
              {travellers === 1 && (
                <p className="bg-[#E9F6FC] px-3 py-2 rounded-[8px] w-fit">
                  You!!
                </p>
              )}
              {travellers === 2 && (
                <p className="bg-[#E9F6FC] px-3 py-2 rounded-[8px] w-fit">
                  & You!!!
                </p>
              )}
            </p>
            <p className="sm:text-center text-left">
              have an authentic experience of Africa
            </p>
          </div>

          <div className="font-satoshi_variable font-medium sm:text-[20px] text-base text-[#5D6D8F] sm:text-center text-left">
            With a combined team of over 20 years experience operating in the
            travel industry, with continent wide partnerships and network, we
            know the continent and understand the industry. Operating from New
            York and Lagos, Nigeria, we have the advantage of local insight
            with global reach.
          </div>
        </div>

        <div className="w-full max-h-[608px] bg-[#FAFAFA] w-[85%] mx-auto rounded-[8px] sm:rounded-[16px]">
          <Image src={images.main} />
        </div>
      </div>

      <div className=" bg-white flex flex-col sm:gap-y-[54px] gap-y-[28px] rounded-[16px] sm:rounded-[24px] py-7">
        <div className="flex flex-col w-[85%] mx-auto ">
          <div className="flex sm:flex-row flex-col w-full items-center">
            <div className="flex flex-col gap-y-[8px] sm:w-[45%] w-full">
              <p className="font-satoshi font-bold text-[14px] text-[#5D6D8F]">
                WHAT WE PROVIDE
              </p>
              <p className="font-outfit text-[#0E4F6D] font-bold text-[24px] sm:text-[36px]">
                We have been simplifying travel experiences in Africa since 2015
              </p>
            </div>
            <div className="sm:w-[55%] w-full font-satoshi_variable font-medium text-[#5D6D8F] sm:text-[20px] text-base">
              We ensure you have a localized experience and get a taste of the
              local culture, festivals, events, and interaction with the people.
              Ensuring real connections in an atmosphere of safety and fun!
            </div>
          </div>
        </div>

        <div className="bg-[#FAFAFA] w-[85%] mx-auto rounded-[8px] sm:rounded-[16px] ">
          <Slider ref={sliderRef} {...settings}>
            {CarouselData?.map((item, index) => {
              return (
                <div key={index} className={`md:h-full w-full flex`}>
                  <ProvisionsCard
                    next={next}
                    picture={item.image}
                    title={item.title}
                    content={item.content}
                  />
                </div>
              );
            })}
          </Slider>
        </div>

        <div
          className={`flex md:hidden flex-col items-end gap-3 justify-end w-[85%] mx-auto`}
        >
          <div className=" gap-2 flex ">
            <div
              className="w-[56px] cursor-pointer aspect-square rounded-full flex justify-center items-center border border-[#E0E0E0] hover:bg-[#1C9FDA] text-[#E0E0E0] hover:text-[#ffffff] font-bold"
              onClick={() => {
                previous();
              }}
            >
              <BsChevronLeft size={20} style={{ fontWeight: "bold" }} />
            </div>
            <div
              className="w-[56px] cursor-pointer aspect-square rounded-full flex justify-center items-center border border-[#E0E0E0] hover:bg-[#1C9FDA] text-[#E0E0E0] hover:text-[#ffffff] font-bold"
              onClick={() => {
                next();
              }}
            >
              <BsChevronRight size={20} style={{ fontWeight: "bold" }} />
            </div>
          </div>
        </div>
      </div>

      <div className=" bg-white flex flex-col gap-y-[44px] rounded-[16px] sm:rounded-[24px] pb-5">
        <div className="flex flex-col w-[85%] mx-auto py-7">
          <div className="font-satoshi font-bold sm:text-[20px] text-base text-[#5D6D8F] sm:text-center text-left">
            OUR MISSION AND VALUES
          </div>
          <div className="font-outfit text-[#0E4F6D] font-bold text-[24px] sm:text-[36px] flex flex-col justify-center items-center">
            <p className="sm:text-center text-left sm:w-fit w-full ">
              Why we do what we do
            </p>
          </div>

          <div className="font-satoshi_variable font-medium sm:text-[18px] text-base text-[#5D6D8F] sm:text-center text-left">
            Our mission is to provide a platform that simplifies the process of
            booking travel experiences in Africa. Offering unique and authentic
            travel experiences that showcase the rich and diverse cultures,
            landscapes, and traditions of Africa. We are driven by the quality
            and authenticity of travel services to create lifelong memories and
            meaningful connections.
          </div>
        </div>

        <div className="w-full max-h-[608px] bg-[#FAFAFA] w-[85%] mx-auto rounded-[8px] sm:rounded-[16px]">
          <Image src={images.us} />
        </div>

        <div className="flex justify-center">
          <p className="md:w-fit w-full">
            <Button
              isBlue
              text={"Join the waitlist"}
              className="font-satoshi_variable font-medium rounded-[8px] px-6 py-4 "
              onClick={() => {
                toggleModal();
              }}
            />
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
