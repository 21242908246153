import Header from "./components/header";
import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Pages from "routes";
import "./index.css";
import Main from "./components/main";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
  from,
  ApolloLink,
  useMutation,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import Register from "components/modals/register";
import { REGISTER_MUTATION } from "GraphQL/Mutations";
import Toast, {
  errorToast,
  successToast,
  warningToast,
} from "components/toast/toast";
import Footer from "components/footer";

function App() {
  const errorLink = onError(({ graphqlErrors, networkError }) => {
    if (graphqlErrors) {
      graphqlErrors.map(({ message, location, path }) => {
        alert(`Graphql error ${message}`);
      });
    }
  });

  const authLink = new ApolloLink((operation, forward) => {
    const token = sessionStorage.getItem("token");

    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    });

    return forward(operation);
  });
  const link = from([
    errorLink,
    new HttpLink({
      uri: process.env.REACT_APP_GRAPHQL_URL,
    }),
  ]);

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    // passing header in url
    link: authLink.concat(link),
  });

  const [showModal, setshowModal] = useState(false);

  const [form, setForm] = useState({
    name: "",
    email: "",
    state: "",
    city: "",
  });

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setForm((prevData) => {
      return {
        ...prevData,
        [name]: type === "checkbox" ? checked : value,
      };
    });
  };

  return (
    <ApolloProvider client={client}>
      <Router>
        <Pages />
      </Router>
      {/* <Register
        showModal={showModal}
        handleBackdropClose={() => {
          setshowModal(false);
        }}
        handleChange={handleChange}
        form={form}
        setForm={setForm}
      />

      <Header
        toggleModal={() => {
          setshowModal(!showModal);
        }}
      />
      <Toast />
      <Main
        toggleModal={() => {
          setshowModal(!showModal);
        }}
      />
      <Footer /> */}
    </ApolloProvider>
  );
}

export default App;
