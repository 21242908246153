import React from "react";
import { companyLink, footerIcons, footerLinks, footerNumbers } from "./items";
import { images } from "constants/index";

const Footer = () => {
  return (
    <div className="bg-[#06202C] text-gray-100 font-satoshi font-normal text-base h-auto sm:pb-[30px] xs:pb-[15%] px-3">
      <div className="grid xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-9 xs:px-[30px] md:px-[30px] lg:px-[90px] lg:grid-cols-9">
        <div className="lg:col-span-4 md:col-span-4 md:border border-[#303030] pt-[83px] md:border-l-0 pb-[34px]">
          <img src={images.fooLogo} />

          <div className="mt-[35px] text-[#D0D5DD] font-medium mb-[24px]">
            Be the first to know when there's a new offer
          </div>

          <div className="flex h-[50px]">
            <input
              placeholder="E.g Yourmail@gmail.com"
              className="bg-[#ffffff] px-[10px] font-medium rounded-l-[8px] w-full md:w-[70%]  placeholder:text-[#5D6D8F] text-black text-[12px]"
            />
            <button className="bg-[#5D6D8F] rounded-r-[8px] md:w-[25%] xs:w-[50%] lg:w-[20%] text-[#fff] text-[12px] font-medium px-3">
              Subscribe
            </button>
          </div>
        </div>

        <div className="md:border border-[#303030] xs:pt-[30px] md:pt-[83px] xs:pl-0 md:pl-[34px] col-span-2 pb-[34px]">
          <div className="mb-[24px] text-lg text-[#98A2B3] text-[14px]">
            COMPANY
          </div>
          {companyLink.map((data) => (
            <div key={data.id} className="mb-[16px] text-[#FCFCFD] font-medium">
              <div to={data.route}>{data.title}</div>
            </div>
          ))}
        </div>

        <div className="lg:col-span-3 md:col-span-3 md:border border-[#303030] xs:pt-[30px] md:pt-[83px] pb-[34px] xs:pl-0 md:pl-[22px] md:border-r-0">
          <div className="mb-[24px] text-lg text-[#98A2B3] text-[14px]">
            CONTACT US
          </div>
          <div className="mb-[16px] flex md:items-start lg:items-center lg:flex-row md:flex-col gap-[16px]">
            {footerNumbers?.phone?.map((data, i) => (
              <div key={i} className="flex items-center gap-[19px]">
                <img src={data?.icon} />
                <div
                  className={` ${
                    i === 1 ? "lg:hidden md:flex xs:hidden" : "hidden"
                  }`}
                ></div>
                <a href={data.link} className="text-[#FCFCFD] font-medium">
                  {data.num}
                </a>
              </div>
            ))}
          </div>
          <div className="mb-[16px] flex items-center gap-[19px]">
            <img src={footerNumbers?.mail?.icon} />
            <a
              href={footerNumbers?.mail?.link}
              className="text-[#FCFCFD] font-medium"
            >
              {footerNumbers?.mail?.text}
            </a>
          </div>
          <div className="mb-[16px] flex items-center gap-[19px]">
            <img src={footerNumbers?.address?.icon} />
            <div className="text-[#FCFCFD] font-medium">
              {footerNumbers?.address?.text}
            </div>
          </div>
        </div>
      </div>

      <div className="px-[30px] md:px-[30px] lg:px-[90px] xs:mt-0 md:mt-[37px] flex flex-col md:flex-row items-center sm:items-start md:items-center justify-center md:justify-between">
        <div className="flex text-[#D0D0D0] flex-col sm:flex-row items-center w-full gap-[10px] sm:gap-[unset] xs:justify-between md:justify-start md:gap-[32px]">
          <div className="font-medium">
            © 2023 Travelbay . All rights reserved.
          </div>

          <div className="flex items-center gap-[16px]">
            {footerLinks?.map((data, i) => (
              <div>{data.title}</div>
            ))}
          </div>
        </div>

        <div className="flex gap-[34px] xs:justify-center sm:justify-start items-center xs:mt-[37px] md:mt-0">
          {footerIcons.map((data) => (
            <a
              key={data.id}
              target={"_blank"}
              rel="noreferrer"
              href={data.route}
            >
              <img className="sm:w-[48px] sm:h-[48px]" src={data.icon} />
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Footer;
