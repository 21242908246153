import { gql } from "@apollo/client";

export const REGISTER_MUTATION = gql`
  mutation addUser(
    $fullName: String!
    $email: Email!
    $state: String!
    $city: String!
    $package: String
    $referral: String
  ) {
    addUser(
      fullName: $fullName
      email: $email
      state: $state
      city: $city
      package: $package
      referral: $referral
    ) {
      __typename
      ... on Success {
        message
      }
      ... on BadRequest {
        message
      }
    }
  }
`;
