import React from "react";
import { HiOutlineArrowUpRight } from "react-icons/hi2";
import { images } from "constants";

const ProvisionsCard = ({ next, picture, title, content }) => {
  return (
    <div className="w-full h-full flex md:flex-row flex-col items-center relative">
      <div className="md:w-[45%] w-full h-full bg-blue-500">
        <p
          className="bg-[#1C9FDA] rounded-full w-[56px] aspect-square justify-center align-center top-[10px] lg:top-[34px] lg:right-[30px] right-[10px] absolute md:block hidden z-30 cursor-pointer"
          onClick={() => {
            next();
          }}
        >
          <p className="flex h-full justify-center items-center">
            <HiOutlineArrowUpRight color={"#ffffff"} />
          </p>
        </p>
        <img
          className="md:h-full h-fit md:w-fit w-full object-cover"
          src={picture}
          alt=""
        />
      </div>
      <div className="md:w-[55%] w-full h-full relative items-center justify-center md:px-4 px-0">
        <div className="w-full h-full flex flex-col items-center justify-center">
          <div className="flex flex-col px-[24px] md:ml-5">
            <p className="font-bold font-outfit lg:text-[40px] sm:text-[30px] text-[20px] w-fulltext-[#0E4F6D] ">
              {title}
            </p>
            <p className="font-satoshi_variable font-medium sm:text-[20px] text-base w-full text-[#5D6D8F]">
              {content}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProvisionsCard;
