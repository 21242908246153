import React, { useContext, useEffect, useRef, useState } from "react";
import { Button } from "./buttons";
import { FiArrowUpRight } from "react-icons/fi";
import canvas from "../constants/images/canvas.svg";
import canvas2 from "../constants/images/canvas.png";
import { ModalContext } from "routes/layout";
import { images } from "constants";
import TripCard from "./cards/trip_card";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";

const Main = () => {
  const { showModal, setshowModal, closeModal, toggleModal } =
    useContext(ModalContext);

  const sliderRef = useRef(null);

  const next = () => {
    sliderRef.current.slickNext();
  };

  const previous = () => {
    sliderRef.current.slickPrev();
  };

  const [windowWidth, setWindowWidth] = useState(
    window.innerWidth || document.documentElement.clientWidth
  );

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth || document.documentElement.clientWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  console.log(windowWidth);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: windowWidth > 450 && "center",
    centerMode: windowWidth > 450 ? true : false,
    centerPadding:
      windowWidth > 1000
        ? "30%"
        : windowWidth > 800
        ? "20%"
        : windowWidth > 700
        ? "15%"
        : windowWidth > 620
        ? "10%"
        : windowWidth > 450
        ? "-5%"
        : 0,
    speed: 3000,
    autoplay: true,
    autoplaySpeed: 10000,
  };

  const testDestination = [
    {
      src: images.afrochella,
      title: "Nigeria - Ghana Christmas Tour!",
      locations: "Nigeria, Ghana",
      date: "SATURDAY , DEC 16, 2023 - WEDNESDAY, JAN 3, 2024",
      inclusions:
        "Visa | Security | Transportation | Acoomodation | Local Flights | Livespot Festival Tickets | Heritage Tours | Calabar Carnival | Afrochella | Eat Drink Festival.",
      price: "3,299",
    },
    {
      src: images.nigeria,
      title: "Christmas In Nigeria",
      locations: "Nigeria",
      date: "WEDNESDAY , DEC 20, 2023 - SATURDAY, DEC 30, 2023",
      inclusions:
        "Visa | Security | Transportation | Acoomodation | Local Flights | Livespot Festival Tickets | Heritage Tours | Calabar Carnival | Eat Drink Festival.",
      price: "1,999",
    },
    {
      src: images.ghana,
      title: "Christmas In Ghana",
      locations: "Ghana",
      date: "WEDNESDAY , DEC 20, 2023 - SATURDAY, DEC 30, 2023",
      inclusions:
        "Visa | Security | Transportation | Acoomodation | Local Flights | Fashion Shows | Heritage Tours | Street Carnival | Afrochella | Food Festivals.",
      price: "1,999",
    },
  ];
  return (
    <div className="bg-[#F5F7F9] w-full flex flex-col gap-y-8">
      <div className="bg_design bg-no-repeat bg-cover bg-center ">
        <div className="flex flex-col gap-y-5 justify-center w-full py-[40px]">
          <p className="font-outfit md:text-[48px] text-[24px] text-[#093549]/90 font-bold text-center w-5/6 mx-auto capitalize">
            Easiest way to have an authentic African Experience{" "}
          </p>

          <p className="font-satoshi_variable text-[#344054]/90 md:text-[20px] text-base text-center w-5/6 mx-auto ">
            Join the waitlist for our Spring 2023 launch and enter a draw to win
            a $200 travel credit voucher!
          </p>
          <div className="pt-4 flex flex-col md:flex-row justify-center gap-4 mx-auto w-fit md:w-full ">
            <a
              href="https://docs.google.com/forms/d/1h725nQon7seTH7_Bqiq8jxIOp9E4W8eCWPYjJ7gJxe0/edit"
              target="_blank"
            >
              <Button
                isWhite
                text={"Participate in our survey"}
                RightIcon
                icon={<FiArrowUpRight size={18} color="#1C9FDA" />}
                className="font-satoshi_variable font-bold rounded-[8px] px-6 py-4 max-w-[300px] items-center"
              />
            </a>
            <Button
              isBlue
              text={"Join the waitlist"}
              className="font-satoshi_variable font-bold rounded-[8px] px-6 py-4 max-w-[300px]"
              onClick={() => {
                toggleModal();
              }}
            />
          </div>
        </div>
        <div className="w-full flex justify-center overflow-x-hidden">
          <img
            className="min-w-[800px] mx-auto overflow-x-hidden  justify-center items-center"
            src={canvas2}
            alt=""
          />
        </div>
      </div>

      <div className="lg:mx-[54px] sm:mx-[24px] mx-[15px]  bg-white rounded-[8px] sm:rounded-[16px] lg:rounded-[8px] sm:px-[16px] lg:px-[32px] px-2 lg:py-[60px] sm:py-[30px] py-[15px] flex flex-col gap-y-[25px] sm:gap-y-[40px] mb-8">
        <div>
          <p className="flex flex-col font-outfit text-[#093549] font-bold sm:text-[36px] text-[24px]">
            {" "}
            <p>Be the first to book our launch deals- </p>{" "}
            <p>specially curated for the best experience this Christmas!</p>{" "}
          </p>
          {/* <p className="w-full md:w-[50%] font-satoshi text-[#475467] text-base sm:text-[18px] font-medium">
            With so much access to the best deals in the market, we’ve made it
            possible for you to reconnect with your roots and enjoy the wonders
            of Africa.
          </p> */}
        </div>

        <div className="w-full flex flex-wrap justify-between gap-3 hidden">
          {testDestination.map((item, index) => {
            return (
              <div key={index}>
                <TripCard
                  src={item.src}
                  title={item.title}
                  locations={item.locations}
                  date={item.date}
                  inclusions={item.inclusions}
                  price={item.price}
                />
              </div>
            );
          })}
        </div>

        <div className="w-full flex flex-col justify-center gap-3  ">
          <div className="w-full mx-auto rounded-[8px] sm:rounded-[16px] ">
            <Slider
              className="flex gap-x-5 justify-center w-full bg-blue-500"
              ref={sliderRef}
              {...settings}
            >
              {testDestination.map((item, index) => {
                return (
                  <div
                    className="flex justify-center mx-auto xs:py-10 p-0 w-full"
                    key={index}
                  >
                    <img
                      className="xs:max-w-[400px] aspect-square w-full object-cover"
                      src={item.src}
                      alt=""
                    />
                  </div>
                );
              })}
            </Slider>
          </div>

          <div className=" gap-2 flex sm:justify-center justify-end w-full">
            <div
              className="w-[56px] cursor-pointer aspect-square rounded-full flex justify-center items-center border border-gray-300 hover:bg-[#1C9FDA] text-gray-300 hover:text-[#ffffff] font-bold"
              onClick={() => {
                previous();
              }}
            >
              <BsChevronLeft size={20} style={{ fontWeight: "bold" }} />
            </div>
            <div
              className="w-[56px] cursor-pointer aspect-square rounded-full flex justify-center items-center border border-gray-300 hover:bg-[#1C9FDA] text-gray-300 hover:text-[#ffffff] font-bold"
              onClick={() => {
                next();
              }}
            >
              <BsChevronRight size={20} style={{ fontWeight: "bold" }} />
            </div>
          </div>
        </div>

        <div className="mx-auto">
          <Button
            isBlue
            text={"Join the waitlist"}
            className="font-satoshi_variable font-bold rounded-[8px] px-6 py-4 max-w-[300px]"
            onClick={() => {
              toggleModal();
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Main;
