import React, { useState } from "react";

const Inputs = ({
  name,
  className,
  type,
  placeholder,
  value,
  disabled,
  onChange,
  label,
  containerClass,
  labelClassName,
  required,
}) => {
  const [withLabel, setWithLabel] = useState(false);

  return (
    <div className={`${containerClass} relative`}>
      {withLabel && (
        <label
          className={`${labelClassName} text-base text-[#093549]/60 ml-3 px-1 absolute z-10 -top-3 bg-white`}
        >
          {label}
        </label>
      )}

      <div
        onClick={() => {
          setWithLabel(!withLabel);
        }}
        className="relative "
      >
        <input
          name={name}
          className={` w-full h-12 border border-gray-400 rounded-[8px] px-4 text-[#093549] placeholder-gray-400 hover:cursor-pointer focus:border-[#1C9FDA] ${
            withLabel && "border-[#1C9FDA]"
          } focus:outline-none ${className}`}
          type={type}
          placeholder={withLabel ? "" : placeholder}
          value={value}
          autoComplete="on"
          disabled={disabled}
          onChange={onChange}
          required={required}
        />
      </div>
    </div>
  );
};
const SelectInput = ({
  name,
  className,
  type,
  placeholder,
  value,
  disabled,
  onChange,
  label,
  containerClass,
  labelClassName,
  required,
  options,
  step,
}) => {
  const [withLabel, setWithLabel] = useState(false);

  const listedItems = options?.map((option, index) => (
    <option className="" value={JSON.stringify(option)}>
      {option?.name}
    </option>
  ));

  return (
    <div className={`${containerClass} relative`}>
      {withLabel && (
        <label
          className={`${labelClassName} text-base text-[#093549]/60 ml-3 px-1 absolute z-10 -top-3 bg-white`}
        >
          {label}
        </label>
      )}

      <div
        onClick={() => {
          setWithLabel(!withLabel);
        }}
        className="relative "
      >
        <select
          name={name}
          className={` w-full h-12 border border-gray-400 rounded-[8px] px-4 text-[#093549] placeholder-gray-400 hover:cursor-pointer focus:border-[#1C9FDA] ${
            withLabel && "border-[#1C9FDA]"
          } focus:outline-none ${className}`}
          type={type}
          placeholder={withLabel ? "" : placeholder}
          value={value}
          autoComplete="on"
          disabled={disabled}
          onChange={onChange}
          required={required}
          step={step}
        >
          <option disabled selected>
            {placeholder}
          </option>
          {listedItems}
        </select>
      </div>
    </div>
  );
};

export { Inputs, SelectInput };
