import Image from "components/lazyImage";
import React from "react";
import { ReactComponent as Location } from "constants/images/location.svg";
import { ReactComponent as Users } from "constants/images/users.svg";
import { ReactComponent as Star } from "constants/images/star.svg";
import { images } from "constants";

const TripCard = ({ src, title, locations, date, inclusions, price }) => {
  return (
    <div className="bg-[#F5F7F9] max-w-[380px] w-full h-[561px] rounded-t-[8px] flex flex-col gap-y-3">
      <div className="w-full h-[250px] rounded-t-[8px] relative">
        <Image
          className="rounded-t-[8px] object-cover w-full h-[250px]"
          src={src}
        />
        <div className="absolute bottom-20 left-12 z-10">
          <div className="relative bg-green-300">
            <p className="absolute left-1/2 top-1/2 -translate-x-1/2 -tarnslate-y-1/2">
              <Star />{" "}
            </p>
            <p className="z-10 absolute flex text-center text-[#093549] font-bold text[14px] left-1/2 top-1/2 -translate-x-1/2 -tarnslate-y-1/2 pt-2">
              Per Person
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col font-satoshi gap-y-[14px] px-[10px]">
        <div className="flex flex-col gap-2 ">
          <p className="font-satoshi font-bold text-[20px] text-[#0E4F6D]">
            {title}
          </p>
          <p className="flex justify-between items-center">
            <p className="flex items-center gap-x-2">
              <Location />{" "}
              <p className="font-bold text-[14px] text-[#5D6D8F] ">
                {locations}
              </p>
            </p>

            <p className="flex items-center gap-x-2 font-bold text-[14px] text-[#5D6D8F]">
              Per traveller <Users />
            </p>
          </p>

          <p className="font-bold text-[#344054]">{date}</p>
        </div>

        <div className="font-medium text-[#475467]">
          <span className="text-[#093549] font-bold"> Includes:</span>{" "}
          {inclusions}
        </div>

        <div className="flex flex-col gap-y-[6px]">
          <p className="text-[#5D6D8F] font-medium">TOTAL PRICE</p>
          <p className="text-[#0E4F6D] text-[24px] font-bold">
            ${price} <span className="font-bold text-base">(room sharing)</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default TripCard;
