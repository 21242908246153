import fooLi from "./images/fooLi.svg";
import fooIg from "./images/fooIg.svg";
import fooFb from "./images/fooFb.svg";
import phone from "./images/phone.svg";
import loca from "./images/loca.svg";
import mail from "./images/mail.svg";
import fooTwit from "./images/fooTwit.svg";
import fooLogo from "./images/fooLogo.svg";
import community from "./images/community.png";
import guide from "./images/guide.png";
import product from "./images/product.png";
import explore from "./images/explore.png";
import main from "./images/main.png";
import us from "./images/us.png";
import ghana from "./images/ghana.jpg";
import nigeria from "./images/nigeria.jpg";
import afrochella from "./images/nigeria and ghana.jpg";

export default {
  fooLi,
  fooIg,
  fooFb,
  fooTwit,
  fooLogo,
  phone,
  loca,
  mail,
  community,
  guide,
  product,
  explore,
  main,
  ghana,
  nigeria,
  afrochella,
  us,
};
