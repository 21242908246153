import React from "react";
import { Route, Routes } from "react-router-dom";
import { Layout } from "./layout";
import Wishlist from "screens/waitlist/index";
import AboutUs from "screens/about_us/index";

const Pages = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Layout>
            <Wishlist />
          </Layout>
        }
      >
        {" "}
      </Route>
      <Route
        path="/referral/:referral_code"
        element={
          <Layout>
            <Wishlist />
          </Layout>
        }
      >
        {" "}
      </Route>
      <Route
        path="/about_us"
        element={
          <Layout>
            <AboutUs />
          </Layout>
        }
      ></Route>
    </Routes>
  );
};

export default Pages;
