import Main from "components/main";
import React, { useState } from "react";

const Wishlist = () => {
  const [showModal, setshowModal] = useState(false);
  return (
    <div>
      <Main
        toggleModal={() => {
          setshowModal(!showModal);
        }}
      />
    </div>
  );
};

export default Wishlist;
