import { Inputs, SelectInput } from "components/inputs";
import React, { useEffect, useState } from "react";
import { Country, State, City } from "country-state-city";
import {
  AiOutlineCheck,
  AiOutlineCloseCircle,
  AiOutlineRight,
} from "react-icons/ai";
import { Button } from "components/buttons";
import { RxDoubleArrowRight } from "react-icons/rx";
import { useMutation } from "@apollo/client";
import { REGISTER_MUTATION } from "GraphQL/Mutations";
import { errorToast, successToast, warningToast } from "components/toast/toast";
import { useParams } from "react-router-dom";

const Register = ({ showModal, handleBackdropClose }) => {
  const [selectedState, setSelectedState] = useState("");
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const { referral_code } = useParams();

  const [form, setForm] = useState({
    name: "",
    email: "",
    referral: "",
    state: "",
    city: "",
    interest: "",
  });

  const refresh = () => {
    setForm((prevData) => {
      return {
        ...prevData,
        name: "",
        email: "",
        referral: "",
        state: "",
        city: "",
        interest: "",
      };
    });
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setForm((prevData) => {
      return {
        ...prevData,
        [name]: type === "checkbox" ? checked : value,
      };
    });
  };

  useEffect(() => {
    if (referral_code) {
      setForm((prevData) => {
        return {
          ...prevData,
          referral: referral_code,
        };
      });
    }
  }, []);

  const countryCode = "US";
  const states = State.getStatesOfCountry(countryCode);
  let citiesFromFirstState;
  if (selectedState) {
    citiesFromFirstState = City.getCitiesOfState(countryCode, selectedState);
  }

  console.log("Cities:", {
    state: states,
    cities: citiesFromFirstState,
    selectedState,
    form,
  });

  const locations = [
    {
      name: "Christmas In Nigeria",
    },
    {
      name: "Christmas in Ghana",
    },
    {
      name: "Nigeria - Ghana Christmas Tour!",
    },
  ];

  const [addUser] = useMutation(REGISTER_MUTATION);

  const handleSubmit = () => {
    console.log(form);
    setLoading(true);
    addUser({
      variables: {
        fullName: form.name,
        email: form.email,
        state: form.state,
        city: form.city,
        package: form.interest,
        referral: form.referral,
      },
    })
      .then((response) => {
        if (response.data.addUser.__typename === "BadRequest") {
          warningToast("Warning", response.data.addUser.message);
        }
        if (response.data.addUser.__typename === "Success") {
          successToast("Success", "Registration Successful");
          setSubmitted(true);
        }
      })
      .catch((error) => {
        errorToast("Error", error ? error?.message : "An error occured ");
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (showModal) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [showModal]);

  const formFilled = () => {
    return !form?.name || !form?.email || !form?.state || !form?.city;
  };

  return (
    <div>
      {showModal && (
        <>
          <div
            id="modal-backdrop"
            className={` fixed w-[100%] h-[100%] top-0 left-0 z-30 grid place-content-center bg-black/40
          `}
            onClick={() => {
              handleBackdropClose();
              setSubmitted(false);
              refresh();
            }}
          ></div>
          <div className="h-[500px] w-[500px]  border-r-2 border-l-2 border-gray-500 z-40 fixed sm:-top-[50%] bottom-0 left-1/2 sm:translate-y-2/4 -translate-x-2/4 border-dashed bounce"></div>
          <div className="modal_scroll modal_bounce fixed sm:bottom-1/2 bottom-0 left-1/2 sm:translate-y-2/4 -translate-x-2/4 box-border w-full sm:w-[500px] max-h-[calc(100%_-_100px)] overflow-y-auto bg-white py-10 px-6 sm:rounded-lg rounded-t-lg xsm:px-12 z-40  modal_scroll">
            <div className="w-full">
              <AiOutlineCloseCircle
                onClick={() => {
                  handleBackdropClose();
                  setSubmitted(false);
                  refresh();
                }}
                className="cursor-pointer"
                size={24}
                color="#667085"
              />
            </div>
            <div className="flex flex-col gap-y-8">
              <div className="w-full flex flex-col gap-x-4 justify-center">
                <p className="text-[#093549] text-center text-[24px] font-bold font-outfit">
                  Welcome to travelbay
                </p>
                <p className="text-[#344054] font-satoshi_variable text-center">
                  Help us get back to you easily. Join the waitlist here.
                </p>
              </div>
              <div className="flex flex-col gap-y-4">
                <Inputs
                  name={"name"}
                  value={form.name}
                  placeholder={"Name"}
                  label={"Name"}
                  onChange={handleChange}
                />
                <Inputs
                  name={"email"}
                  value={form.email}
                  placeholder={"Email address"}
                  label={"Email address"}
                  onChange={handleChange}
                />
                <Inputs
                  name={"referral"}
                  value={form.referral}
                  placeholder={"Referral Code"}
                  label={"Referral Code"}
                  onChange={handleChange}
                />
                <SelectInput
                  options={states?.length > 0 ? states : [""]}
                  placeholder={"State"}
                  label={"State"}
                  name={"state"}
                  onChange={(event) => {
                    const { name, value, type, checked } = event.target;
                    const newValue = JSON.parse(value);
                    console.log("the event", JSON.parse(value)?.name);

                    setSelectedState(newValue.isoCode);
                    setForm((prevData) => {
                      return {
                        ...prevData,
                        [name]: type === "checkbox" ? checked : newValue?.name,
                      };
                    });
                  }}
                />
                <SelectInput
                  options={
                    citiesFromFirstState?.length > 0
                      ? citiesFromFirstState
                      : [""]
                  }
                  placeholder={"City"}
                  label={"City"}
                  name={"city"}
                  onChange={(event) => {
                    const { name, value, type, checked } = event.target;
                    const newValue = JSON.parse(value);

                    setForm((prevData) => {
                      return {
                        ...prevData,
                        [name]: type === "checkbox" ? checked : newValue?.name,
                      };
                    });
                  }}
                />

                <p className="font-medium font-satoshi text-[#093549] text-[14px]">
                  Kindly select your preferred package for Christmas
                </p>

                <SelectInput
                  options={locations}
                  placeholder={"Select an option"}
                  label={"Preferred destination"}
                  name={"interest"}
                  onChange={(event) => {
                    const { name, value, type, checked } = event.target;
                    const newValue = JSON.parse(value);

                    setForm((prevData) => {
                      return {
                        ...prevData,
                        [name]: type === "checkbox" ? checked : newValue?.name,
                      };
                    });
                  }}
                />
              </div>
              <div className="flex flex-col gap-y-3">
                <Button
                  isBlue
                  text={submitted ? "Subscribed" : "Join the waitlist "}
                  className="font-satoshi_variable font-bold rounded-[8px] px-6 py-3"
                  RightIcon
                  icon={
                    submitted ? (
                      <AiOutlineCheck size={20} />
                    ) : (
                      <div className="flex gap-x-1">
                        <AiOutlineRight /> <AiOutlineRight />
                      </div>
                    )
                  }
                  disabled={formFilled()}
                  onClick={() => handleSubmit()}
                  loading={loading}
                />
                {submitted && (
                  <p className="font-satoshi_variable text-[#12B76A] font-medium text-center justify-center">
                    You’ve been subscribed to our waitlist !
                  </p>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Register;
