import React, { useState } from "react";
import LazyLoad from "react-lazyload";
import placeholderImage from "constants/images/placeholder.jpg";
import { images } from "constants";
import { BsPlusSlashMinus } from "react-icons/bs";

const Image = ({ src, alt, className, ...rest }) => {
  const [imageSrc, setImageSrc] = useState(placeholderImage);

  const handleImageLoad = () => {
    setImageSrc(src);
  };

  const VStyles =
    imageSrc === placeholderImage
      ? { filter: "blur(10px)", objectFit: "cover" }
      : { objectFit: "cover" };
  return (
    <LazyLoad className="h-full" once>
      <img
        {...rest}
        src={imageSrc}
        onLoad={handleImageLoad}
        data-src={src}
        alt={alt}
        style={{
          filter: imageSrc === placeholderImage ? "blur(10px)" : "",
          objectFit: "cover",
        }}
        className={`${className} mx-auto object-cover overflow-hidden`}
      />
    </LazyLoad>
  );
};

export default Image;
